<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',
};
</script>

<style lang="scss">
@import url('https://fonts.cdnfonts.com/css/poppins');
@import url('https://fonts.cdnfonts.com/css/body');
@import url('https://fonts.cdnfonts.com/css/inter');

$fontBody: 'Body', sans-serif;
$fontPoppins: 'Poppins', sans-serif;
$fontInter: 'Inter', sans-serif;
$primary: rgba(114, 86, 164, 1) !default;
$greyMedim: #6e6e6e;
$greyDark: #323232;
$colorError: #F31155;

#main{
  width: calc(100% - 315px);
  margin-left: 315px;
  transition: all ease 0.5s;
}

.main-full{
  margin-left: 0 !important;
  width: 100% !important;
  transition: all ease 0.6s;
}

html, body {
  background: #F9FBFC !important;
  overflow-x: hidden;
}

*:focus {
  outline: none !important;
}
.btn:focus {
  box-shadow: none !important;
}

h1, h2, h3, h4, h5, h6, strong {
  font-family: $fontPoppins;
  letter-spacing: 1px;
}

h1 {
  font-size: 38px !important;
  font-weight: 700 !important;
}

h2 {
  font-size: 28px !important;
  font-weight: bold;
}

h3 {
  font-size: 20px;
  font-weight: bold;
}

h4 {
  font-size: 16px !important;
  font-weight: 600 !important;
}

h5 {
  font-size: 14px;
  font-weight: 600;
}

p {
  font-family: $fontInter !important;
  font-weight: 400;
}

input[type="text"]:not(.multiselect__input), input[type="password"], input[type="email"], .v-money {
  padding: 13px 8px 13px 8px;
  width: 100%;
  border: 1px solid #E9EDF7;
  border-radius: 8px;
  height: 52px;
  &::placeholder {
    color: #6e6e6e;
    font-size: 14px;
    font-family: $fontPoppins !important;
    font-weight: 600;
    font-size: 14px;
  }
}

select {
  width: 100%;
  border: 1px solid #E9EDF7 !important;
  border-radius: 8px;
  margin-top: 0 !important;
  height: 52px !important;
  font-size: 14px !important;

  &.primary {
    font-family: $fontPoppins !important;
    font-weight: 600 !important;
    color: #6e6e6e !important;
  }

  &.secondary {
    font-family: $fontInter !important;
    font-weight: 400 !important;
    color: #6e6e6e !important;
  }

  &:focus {
    box-shadow: none !important;
  }
}

.t-primary {
  color: $primary !important;
}

button {
  border-radius: 8px !important;
  font-family: $fontPoppins !important;
  font-weight: 600 !important;
}

span, p {
  color: $greyMedim;
  font-weight: 400;
  font-family: $fontInter;
}

.f-grey {
  color: $greyMedim !important;
}

.btn-primary {
  background-color: $primary !important;
  border-color: $primary !important;
}

.btn-block {
  height: 41px !important;
}

.btn-lg {
  height: 48px !important;
  line-height: 30px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  font-family: $fontPoppins !important;
}

.btn-default {
  color: $primary !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  font-family: $fontPoppins !important;
}

.btn-light {
  font-weight: 600 !important;
}

.btn-outline-default {
  border: 1px solid #E9EDF7 !important;
  font-weight: 600 !important;
  color: $primary !important;
}

.btn-outline-primary {
  border: 1px solid $primary !important;
  font-weight: 600 !important;
  color: $primary !important;

  &:hover {
    background: $primary !important;
    color: #fff !important;

    svg path {
      fill: #fff !important;
    }
  }
}

.btn-outline-danger {
  border: 1px solid #F31155 !important;
  &:hover {
    background: #F31155 !important;
    color: #fff !important;

    svg path {
      fill: #fff !important;
    }
  }
  svg path {
    fill: #F31155 !important;
  }
}
.mt-6 {
  margin-top: 6rem !important;
}

.mt-7 {
  margin-top: 7rem !important;
}

.fontPoppins {
  font-family: $fontPoppins !important;
}

.fontInter {
  font-family: $fontInter !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.f-sm {
  font-size: 12px !important;
}

.form-group--error {
  border: 2px solid $colorError !important;
}

.navbar-white {
  border-bottom: 1px solid #dedede;
}

.card {
  box-shadow: 0px 8px 32px 0px #0000000F;
  border-radius: 16px !important;
  border: none !important;

  .card-title {
    font-size: 18px !important;
    font-weight: 700 !important;
    font-family: $fontPoppins !important;
  }

  .card-header {
    background: transparent !important;
    padding: 5px 0 !important;
    border: 1px solid #E9EDF7;
    border-radius: 8px !important;
  }
}

.btn-unstyled {
  background: transparent !important;
  font-weight: 700 !important;
  font-family: $fontPoppins !important;
}

.table thead tr {
  background: #F1EEF6;
  color: $primary;
  font-family: $fontPoppins !important;
  font-size: 14px !important;
  th {
    border: none !important;
  }
  div {
    font-weight: 600 !important;
  }
}

.table tbody tr  {
  td {
    border-bottom: 1px solid #E9EDF7 !important;
    border-top: none !important;
    font-family: $fontInter !important;
    color: #6e6e6e !important;
    font-size: 13px !important;
  }
}

.table.b-table > thead > tr > [aria-sort], .table.b-table > tfoot > tr > [aria-sort] {
  background-size: 1.65em 1em !important;
}

.table.b-table > thead > tr > [aria-sort=none], .table.b-table > tfoot > tr > [aria-sort=none] {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwLjQ1MDIgNy40Njk5N0MxMC4yNjAyIDcuNDY5OTcgMTAuMDcwMiA3LjQgOS45MjAyIDcuMjVMNi43MzAyIDQuMDZMMy41NDAxOSA3LjI1QzMuMjUwMTkgNy41NCAyLjc3MDIgNy41NCAyLjQ4MDIgNy4yNUMyLjE5MDIgNi45NiAyLjE5MDIgNi40OCAyLjQ4MDIgNi4xOUw2LjIwMDIzIDIuNDY5OTdDNi4zNDAyMyAyLjMyOTk3IDYuNTMwMiAyLjI1IDYuNzMwMiAyLjI1QzYuOTMwMiAyLjI1IDcuMTIwMjIgMi4zMjk5NyA3LjI2MDIyIDIuNDY5OTdMMTAuOTgwMiA2LjE5QzExLjI3MDIgNi40OCAxMS4yNzAyIDYuOTYgMTAuOTgwMiA3LjI1QzEwLjgzMDIgNy40IDEwLjY0MDIgNy40Njk5NyAxMC40NTAyIDcuNDY5OTdaIiBmaWxsPSIjNzI1NkE0Ii8+CjxwYXRoIGQ9Ik02LjcyOTk4IDIxLjc1QzYuMzE5OTggMjEuNzUgNS45Nzk5OCAyMS40MSA1Ljk3OTk4IDIxVjNDNS45Nzk5OCAyLjU5IDYuMzE5OTggMi4yNSA2LjcyOTk4IDIuMjVDNy4xMzk5OCAyLjI1IDcuNDc5OTggMi41OSA3LjQ3OTk4IDNWMjFDNy40Nzk5OCAyMS40MSA3LjEzOTk4IDIxLjc1IDYuNzI5OTggMjEuNzVaIiBmaWxsPSIjNzI1NkE0Ii8+CjxwYXRoIGQ9Ik0xNy4yOCAyMS43NDk3QzE3LjA4IDIxLjc0OTcgMTYuODkgMjEuNjY5OCAxNi43NSAyMS41Mjk4TDEzLjAzIDE3LjgwOTdDMTIuNzQgMTcuNTE5NyAxMi43NCAxNy4wMzk3IDEzLjAzIDE2Ljc0OTdDMTMuMzIgMTYuNDU5NyAxMy44IDE2LjQ1OTcgMTQuMDkgMTYuNzQ5N0wxNy4yOCAxOS45Mzk3TDIwLjQ2OTkgMTYuNzQ5N0MyMC43NTk5IDE2LjQ1OTcgMjEuMjQgMTYuNDU5NyAyMS41MyAxNi43NDk3QzIxLjgyIDE3LjAzOTcgMjEuODIgMTcuNTE5NyAyMS41MyAxNy44MDk3TDE3LjgxIDIxLjUyOThDMTcuNjcgMjEuNjY5OCAxNy40NyAyMS43NDk3IDE3LjI4IDIxLjc0OTdaIiBmaWxsPSIjNzI1NkE0Ii8+CjxwYXRoIGQ9Ik0xNy4yNyAyMS43NUMxNi44NiAyMS43NSAxNi41MiAyMS40MSAxNi41MiAyMVYzQzE2LjUyIDIuNTkgMTYuODYgMi4yNSAxNy4yNyAyLjI1QzE3LjY4IDIuMjUgMTguMDIgMi41OSAxOC4wMiAzVjIxQzE4LjAyIDIxLjQxIDE3LjY5IDIxLjc1IDE3LjI3IDIxLjc1WiIgZmlsbD0iIzcyNTZBNCIvPgo8L3N2Zz4K") !important;
}
.table.b-table > thead > tr > [aria-sort=descending], .table.b-table > tfoot > tr > [aria-sort=descending] {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTExLjMzMzcgNS45OTkzNUwxMC4zOTM3IDUuMDU5MzVMNi42NjY5OSA4Ljc3OTM1VjAuNjY2MDE2SDUuMzMzNjZWOC43NzkzNUwxLjYxMzY2IDUuMDUyNjhMMC42NjY5OTIgNS45OTkzNUw2LjAwMDMzIDExLjMzMjdMMTEuMzMzNyA1Ljk5OTM1WiIgZmlsbD0iIzcyNTZBNCIvPgo8L3N2Zz4K") !important;
}
.table.b-table > thead > tr > [aria-sort=ascending], .table.b-table > tfoot > tr > [aria-sort=ascending] {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE4LjA3IDkuNTdMMTIgMy41TDUuOTI5OTkgOS41NyIgc3Ryb2tlPSIjNzI1NkE0IiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTEyIDIwLjQ5OTlWMy42Njk5MiIgc3Ryb2tlPSIjNzI1NkE0IiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==") !important;
}

.modal-title {
  display: flex;
  align-items: center;

  h4 {
    color: $greyDark;
    font-weight: 700 !important;
  }
}

.modal-header {
  align-items: center !important;
}

.multiselect__option--highlight {
  background: #F1EEF6 !important;
  font-family: 'Inter', sans-serif !important;
  &::after {
    background: #F1EEF6 !important;
    color: $greyDark !important;
  }
}

.badge-primary-light {
  background-color: #7256a45c;
  span {
    color: $primary !important;
    font-weight: 600 !important;
    font-size: calc(25.6px) !important;
  }
}
.lv-overlaypanel {
  z-index: 9999 !important;
}
.lv-colorpicker-wrapper {
  width: 100% !important;
}

.lv-input__label {
  font-family: $fontPoppins;
  font-weight: 500 !important;
  color: $primary !important;
  margin-bottom: 0 !important;
}

.cards-container {
  height: 84px;
  background-size: cover;
  display: flex;

  label {
    color: #fff;
    font-family: $fontPoppins;
    font-weight: 600;
    font-size: 10px;
    margin-bottom: 0 !important;
  }

  h2 {
    color: #FFB800;
    font-size: 22px !important;
    font-weight: 700 !important;
    margin-bottom: 0 !important;
  }
}

.card-purple {
  background-image: url('../public/img/card-purple.png') !important;
}

.card-pink {
  background-image: url('../public/img/card-pink.png') !important;
}

.card-green {
  background-image: url('../public/img/card-green.png') !important;
}

.card-red {
  background-image: url('../public/img/card-red.png') !important;
}

.card-infos {
  margin-top: 21px;
  margin-left: 16px;
}

.tabs {
  background: $primary !important;
  padding-top: 20px;
  border-radius: 16px 16px 20px 20px;
  box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.0588235294);

  .tab-pane {
    padding: 20px !important;
    background: #fff !important;
    border-radius: 0px 0px 16px 16px !important;
  }

  .nav-link {
    color: #8E78B6;
    font-family: $fontPoppins !important;
    font-weight: 600 !important;
    border: none !important;
    &:first-child {
     margin-left: 20px;
    }
    &:hover {
      color: #fff !important;
    }
    &.active {
      color: $primary !important;
      border-radius: 8px 8px 0px 0px;
    }
  }
}

.input-yellow {
  background: #fff8e5 !important;
}

/* CSS talk bubble */
.talk-bubble {
  display: inline-block;
  position: relative;
  width: 100%;
  height: auto;
  background-color: #ffe8ab;
  border: 1px solid #ffe8ab;
  border-radius: 16px;
}

/* Right triangle, left side slightly down */
.tri-right.border.left-in:before {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  left: -40px;
  right: auto;
  top: 30px;
  bottom: auto;
  border: 20px solid;
}

.tri-right.left-in:after {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  left: -20px;
  right: auto;
  top: 38px;
  bottom: auto;
  border: 12px solid;
  border-color: #ffe8ab #ffe8ab transparent transparent;
}

.talktext {
  padding: 25px;
  text-align: left;
  line-height: 1.5em;

  strong {
    font-family: $fontPoppins !important;
    font-weight: 600 !important;
    color: $greyMedim !important;
    font-size: 12px;
  }
}

.talktext p {
  font-family: $fontInter !important;
  font-style: italic;
  color: $greyDark;
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
}

.multiselect {
  &.secondary {
    .multiselect__tags{
      padding-top: 13px !important;
      border-radius: 8px !important;
      border: 1px solid #E9EDF7 !important;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.rounded {
  border-radius: 8px !important;
}

.multiselect__tags {
  min-height: 52px !important;
}

.multiselect__placeholder {
  padding-top: 7px !important;
}

.multiselect__select {
  height: 45px !important;
}
.multiselect__input, .multiselect__single {
  padding-top: 7px !important;
}
.card.header {
  h2 {
    font-weight: 700 !important;
    color: #323232;
  }
  p {
    color: #7256a4;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
  }
}

.vc-container {
  width: 100% !important;
  border: none !important;

  .vc-title {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    color: #6E6E6E !important;
    text-transform: capitalize;
  }

  .vc-header {
    border-bottom: 1px solid #E9EDF7 !important;
    padding-bottom: 12px;
    margin-top: 5px;
  }

  .vc-arrows-container.title-left {
    margin-right: 12px;
  }

  .vc-weeks {
    margin-top: 15px;
  }

  .vc-day-content {
    font-family: $fontPoppins !important;
    font-weight: 600 !important;
  }

  .vc-highlight {
    border-radius: 4px !important;
  }

  .vc-weekday {
    font-family: $fontPoppins !important;
    font-weight: 600 !important;
    color: #7256a4;
    font-size: 14px !important;
  }

  .vc-arrow {
    border: 1px solid $primary;
    padding-bottom: 2px;
    margin-left: 10px;
    display: none;
  }
}

.details-card {
  background-size: cover;
  background-repeat: no-repeat;
  height: 97px;
  .infos {
    height: 97px;
    display: flex;
    align-items: center;
    padding-left: 25px;
    padding-top: 15px;
    label {
      color: #fff;
      font-family: 'Poppins', sans-serif !important;
      font-weight: 600;
      font-size: 13px;
      margin-bottom: 0 !important;
    }
    h2 {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: #FFB800;
      max-width: 220px;
    }
  }
}
</style>
