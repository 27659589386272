import CostCenterService from '@/services/costCenter';
import PaymentFormService from '@/services/paymentForm';

const state = {
  costCenters: [],
  paymentForms: [],
};

const getters = {
  costCenters: (state) => state.costCenters,
  paymentForms: (state) => state.paymentForms,
};

const mutations = {
  setCostCenters(state, costCenters) {
    state.costCenters = costCenters;
  },

  setPaymentForms(state, paymentForms) {
    state.paymentForms = paymentForms;
  },
};

const actions = {
  async getCostCenters({ commit }) {
    const { data } = await CostCenterService.getList();

    const costCenters = data.map((costCenter) => ({
      id: costCenter.id,
      name: costCenter.description,
    }));

    commit('setCostCenters', costCenters);
  },

  async getPaymentForms({ commit }) {
    const { data } = await PaymentFormService.getList();

    const paymentForms = data.map((paymentForm) => ({
      ...paymentForm,
      value: paymentForm.id,
      text: paymentForm.description,
    }));

    commit('setPaymentForms', paymentForms);
  },

  async filterPaymentForms({ state }, ids) {
    return state.paymentForms.filter((paymentForm) => ids.includes(paymentForm.value));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
